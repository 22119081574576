export const environment = {
  domainSpecificUrls: [
    {
      domain: 'se-financial-reports.ui.stage.ngin-staging.com',
      default: true,
      api: 'https://api.stage.ngin-staging.com/',
      user: 'https://user.stage.ngin-staging.com/',
    },
    {
      domain: 'se-financial-reports.ui.sestage.us',
      api: 'https://se-api.sestage.us/',
      user: 'https://user.sestage.us/',
    },
  ],
  production: false,
  staging: true,
  frameRouterOrigin: [
    'https://app.stage.ngin-staging.com',
    'https://org.stage.ngin-staging.com',
    'https://org.ui.stage.ngin-staging.com',
  ],
  frameRouterRedirectUrl: 'https://org.stage.ngin-staging.com/org/:orgId/financial_reports/*',
  urls: {
    hq: 'https://app.stage.ngin-staging.com',
    ngin_admin: 'https://admin.stage.ngin-staging.com',
  },
  launchDarklyClientId: '5f4fc4507c0c78095ceeb4ed',
  name: 'staging',
};

export function newRelicInit() {
  const element = document.getElementsByTagName("body")[0];
  const script = document.createElement("script");
  switch (window.location.hostname) {
    case "se-financial-reports.ui.stage.ngin-staging.com":
      script.src = "../assets/newrelic/newrelic.stage.js";
      break;
    case "se-financial-reports.ui.sportsengine.com":
      script.src = "../assets/newrelic/newrelic.prod.js";
  }

  element.appendChild(script);
}

import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SeAuthService, RoleAssignment } from 'se-fe-auth-support';
import { CURRENT_ORG_ID } from '../providers/current-org-id.provider';

@Injectable({
  providedIn: 'root',
})
export class AppPermissionGuard implements CanActivate, CanActivateChild {
  private reportViewRoles: RoleAssignment[];

  constructor(
    @Inject(CURRENT_ORG_ID) protected currentOrgId: string,
    public router: Router,
    public authService: SeAuthService
  ) {
    this.reportViewRoles = [
      { role: 'financial_admin', resource_type: 'Platform' },
      { role: 'financial_client_admin', resource_type: 'Organization', resource_id: `${currentOrgId}` },
      { role: 'platform_admin', resource_type: 'Platform' },
      { role: 'financial_support', resource_type: 'Platform' },
    ];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authService.hasAnyRole(this.reportViewRoles).then((result) => {
        observer.next(result);
        observer.complete();
        if (!result) {
          this.router.navigate(['access-denied']);
        }
      });
    });
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}

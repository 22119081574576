import * as _ from 'lodash';

export class MappableModel {
  public metadata: unknown = {};
  public errors: unknown;

  public constructor(data: unknown) {
    this.update(data);
  }

  public update(data: unknown) {
    _.assign(this, data);
  }
}

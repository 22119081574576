import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LOCATION_INITIALIZED } from '@angular/common';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SeFeFrameRouterConfig, SeFeFrameRouterModule } from 'se-fe-frame-router';
import { SeFeAuthSupportModule } from 'se-fe-auth-support';

import { AccessDeniedComponent } from './core/components/access-denied/access-denied.component';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';

import { environment } from '../environments/environment';
import { baseHrefProvider } from './core/providers/base-href.provider';
import { AppRoutingModule } from './app-routing.module';
import { SeFeInlineListModule } from 'se-fe-inline-list';
import { HeaderModule } from './core/components/header/header.module';
import { SeAnalyticsModule } from 'se-analytics';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized
        .then(() => {
          const langToSet = 'en';
          translate.setDefaultLang(langToSet);
          translate.use(langToSet).subscribe(() => {
            resolve(null);
          });
        })
        .catch(() => resolve(null));
    });
}

@NgModule({
  declarations: [AccessDeniedComponent, AppComponent, HomePageComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    SeFeFrameRouterModule.forRoot({
      origin: environment.frameRouterOrigin,
      redirectUrl: environment.frameRouterRedirectUrl,
    } as SeFeFrameRouterConfig),
    HttpClientModule,
    SeFeAuthSupportModule.forRoot(environment),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SeFeInlineListModule,
    HeaderModule,
    SeAnalyticsModule.forRoot({
      platProp: 'se_financial_reports_ui',
      platformSubSec: 'HQ',
      currentEnv: environment.name,
    }),
  ],
  providers: [
    baseHrefProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { UserEmailAddress } from './user-email-address.model';
import { MappableModel } from './mappable-model';

export class User extends MappableModel {
  public email_addresses: UserEmailAddress[];
  public first_name: string;
  public id: number;
  public last_name: string;
  public uri: string;
  public user_name: string;
  public uuid: string;

  public get emailAddress(): string {
    if (this.email_addresses && this.email_addresses.length) {
      return this.email_addresses[0].address;
    }
    return '';
  }

  public get fullName(): string {
    const names: string[] = [];
    if (this.first_name) names.push(this.first_name);
    if (this.last_name) names.push(this.last_name);
    return names.join(' ');
  }

  public isAuthenticated(): boolean {
    return !!this.id;
  }
}

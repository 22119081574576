import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { first } from 'rxjs/operators';
import { Mapper } from './mapper.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public user$: Observable<User> = this.userSubject.asObservable();

  constructor(private api: ApiService) {}

  public current(): void {
    this.getUser()
      .pipe(first())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((data: any) => {
        const user = Mapper.as<User>((data.result && data.result.user) || {}, User);
        this.userSubject.next(user);
      });
  }

  public getUser(): Observable<User> {
    return this.api.get('oauth/me');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { SeFeTabsModule } from 'se-fe-tabs';
import { SeFePageModule } from 'se-fe-page';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SeFeLinkModule } from 'se-fe-link';
import { SeFeHeaderModule } from 'se-fe-header';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    SeFeTabsModule,
    SeFePageModule,
    RouterModule,
    TranslateModule,
    SeFeLinkModule,
    SeFeHeaderModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}

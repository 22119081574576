import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SeEnvironmentUrlService } from 'se-fe-auth-support';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected defaultOptions: any = {};

  constructor(private http: HttpClient, private urlService: SeEnvironmentUrlService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get(path: string, options?: any): Observable<any> {
    return this.http.get(this.uri(path), this.getOptions(options));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public post(path: string, body: any, options?: any): Observable<any> {
    return this.http.post(this.uri(path), body, this.getOptions(options));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public put(path: string, body: any, options?: any): Observable<any> {
    return this.http.put(this.uri(path), body, this.getOptions(options));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public delete(path: string, options?: any): Observable<any> {
    return this.http.delete(this.uri(path), this.getOptions(options));
  }

  protected uri(path: string): string {
    return `${this.urlService.getApiUrl()}${encodeURI(path)}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getOptions(options: any = {}): any {
    options = { ...this.defaultOptions, ...options };
    options.withCredentials = true;
    options.headers = options.headers || {};
    options.headers.Accept = options.headers.Accept || 'application/json';
    return options;
  }
}

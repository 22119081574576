import { Component, DoCheck, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LegacyReportLinkPipe } from '../../pipes/legacy-report-link.pipe';
import { filter, map } from 'rxjs/operators';
import { SeAnalyticsService } from 'se-analytics';
import { BossResources } from 'se-resource-types';
import { environment } from 'src/environments/environment';
import { FiscalYearService } from '../../services/fiscal-year.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements DoCheck {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  header!: TemplateRef<any>;
  showMainHeader = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compRef!: any;
  reportTabs = [
    { routerLink: '/payouts', text: 'Payouts' },
    { routerLink: '/receivables', text: 'Receivables' },
    { routerLink: '/sale-items', text: 'Sale Items' },
    { routerLink: '/adjustments', text: 'Adjustments' },
    { routerLink: '/payments-received', text: 'Payments' },
    { routerLink: '/refunds', text: 'Refunds (Beta)' },
  ];
  legacyReportUrl!: string;
  dataLoaded = false;
  receivablesTitle = 'Receivables';
  legacyReportPipe: LegacyReportLinkPipe = new LegacyReportLinkPipe();
  public currentOrg: BossResources.Organization;
  public backLink = '..';
  public backLinkQueryParams: { [key: string]: string };
  public backLinkTranslationKey: string;
  public reportsHeaderSubtitle = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public fiscalYearService: FiscalYearService,
    private seAnalytics: SeAnalyticsService
  ) {
    this.currentOrg = this.activatedRoute.snapshot.data.currentOrg;

    this.createReportsSubtitleHeader();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((activatedRoute) => {
        this.currentOrg = activatedRoute.data.currentOrg;
        this.legacyReportUrl = this.legacyReportPipe.transform(activatedRoute.data.currentOrg);
      });
  }

  ngDoCheck() {
    if (this.compRef?.header && this.compRef?.dataLoaded) {
      this.backLinkTranslationKey = this.compRef.backLinkTranslationKey || '';
      this.backLink = this.compRef.backLink || '..';
      this.backLinkQueryParams = this.compRef.backLinkQueryParams || null;
      this.header = this.compRef.header;
      this.showMainHeader = false;
      this.dataLoaded = this.compRef.dataLoaded;
    }
    if (this.compRef && !this.compRef.loading) {
      this.dataLoaded = !this.compRef.loading;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onActivate(componentRef: any): void {
    this.compRef = componentRef;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDeactivate(componentRef: unknown): void {
    this.showMainHeader = true;
  }

  legacyReports() {
    this.seAnalytics.push({
      type: 'seEvent',
      data: {
        depth1: 'HQ',
        depth2: 'FinancialReports',
        depth3: 'Payouts',
        action: 'ViewLegacyReports.Click',
        event_type: 8,
      },
    });
    window.open(this.legacyReportUrl, '_blank');
  }

  private createReportsSubtitleHeader(): void {
    this.fiscalYearService.find().subscribe((result) => {
      const startDate = DateTime.fromJSDate(this.fiscalYearService.fiscalYearStartDate(result)).toFormat('MMM d');
      const endDate = DateTime.fromJSDate(this.fiscalYearService.fiscalYearEndDate(result)).toFormat('MMM d');
      this.reportsHeaderSubtitle = `Fiscal Year ${startDate} - ${endDate}`;
    });
  }

  public financialSettingsUrl(): string {
    return `${environment.urls.hq}/org/${this.currentOrg.id}/financial_settings/account-detail`;
  }
}

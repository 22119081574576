<header>
  <se-fe-page
    [seFeDataBackLink]="backLink"
    [seFeDataBackQueryParams]="backLinkQueryParams"
    seFeDataBackText="{{ !showMainHeader ? (backLinkTranslationKey | translate) : '' }}"
    [seFeDataLoaded]="dataLoaded"
    SeFeDataWidth="full">

    <div seFePageHeader>
      <ng-container *ngTemplateOutlet="showMainHeader ? mainHeader: header">
      </ng-container>
      <ng-template #mainHeader>
        <se-fe-header seFePageHeader size="750">
          <div seFeHeaderTitle class="header-top">
            Financial Reports
          </div>
          <ng-container seFeHeaderSubtitle *ngIf="dataLoaded">
            {{ reportsHeaderSubtitle }}
            <a [href]="financialSettingsUrl()" class="pl-link" target="_top">
              Edit
            </a>
          </ng-container>
          <div seFeHeaderActions>
            <a seFeLink class="legacyReportLink" (click)="legacyReports()">
              View Legacy Reports
            </a>
          </div>
        </se-fe-header>
      </ng-template>
    </div>

    <div seFePageTabs *ngIf="dataLoaded && showMainHeader">
      <se-fe-tabs class="se-fe-page-tabs" seFePageTabs [seFeDataTabs]="reportTabs"></se-fe-tabs>
    </div>



    <div seFePageContent>
      <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></router-outlet>
    </div>

  </se-fe-page>

</header>

export class Mapper {
  public static as<T>(data: unknown, klass: new (...args: unknown[]) => T): T {
    const result = data || {};
    return new klass(result);
  }

  public static asArray<T>(data: unknown, klass: new (...args: unknown[]) => T): T[] {
    const result: T[] = (data as T[]) || [];
    return result.map((r) => new klass(r));
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { BossResources } from 'se-resource-types';

type Organization = BossResources.Organization;

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private api: ApiService) {}

  public find(id: string): Observable<Organization> {
    return this.api
      .get(`organizations/${id}`)
      .pipe(first())
      .pipe(map((data) => data.result as Organization));
  }
}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BossResources } from 'se-resource-types';
import { CURRENT_ORG_ID } from '../providers/current-org-id.provider';
import { OrganizationService } from '../services/organization.service';

type Organization = BossResources.Organization;

@Injectable({ providedIn: 'root' })
export class OrganizationResolver implements Resolve<Organization> {
  constructor(@Inject(CURRENT_ORG_ID) protected currentOrgId: string, private service: OrganizationService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organization> {
    return this.service.find(this.currentOrgId);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { BossResources } from 'se-resource-types';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'legacyReportLink',
})
export class LegacyReportLinkPipe implements PipeTransform {
  transform(currentOrg: BossResources.Organization): string {
    const link = `${environment.urls.hq}/org/${currentOrg.id}/reports/payouts`;
    return link;
  }
}
